<template>
    <div class="box">
        <div class="box-header with-border text-center">
            <h3>
                <previous-route-block/>

                {{ page_title }}
            </h3>

        </div>

        <div class="box-body text-center">
            <h5 class="text-muted">
                Попробуйте повторить ещё раз через некоторое время или обратитесь в поддержку
            </h5>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'server_error',

        components: {

        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Ошибка 500',
        }),
    }
</script>